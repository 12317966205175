<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{$t('settings.raw-materials.mainDialog.edit.title')}}</h3>
        <h3 v-else>{{$t('settings.raw-materials.mainDialog.add.title')}}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                  outlined
                  :rules="requiredRules"
                  :label="$t('settings.raw-materials.labels.name')"
                  maxlength="100"
                  v-model="form.name" />
            </v-col>

            <!-- Description -->
            <v-col cols="12" class="mt-12">
              <v-textarea
                  outlined
                  :label="$t('settings.raw-materials.labels.description')"
                  v-model="form.description" />
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "RawMaterialMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},

      defaultForm: {
        name: "",
        description: ""
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        if (this.item)
          this.submitEdit();
        else
          this.submitAdd();
      }
    },

    submitAdd() {
      this.$http.post(`/settings/raw-materials`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('settings.raw-materials.mainDialog.add.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },


    submitEdit() {

      this.$http
          .put(`/settings/raw-materials/${this.item.id}`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('settings.raw-materials.mainDialog.edit.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    }

  }
}
</script>

<style scoped>

</style>
